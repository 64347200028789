<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-if="canEditSet"
              variant="primary"
              :disabled="isAddRecordActive"
              @click="openSetModel(false)"
            >
              <span>{{ $t('BUTTON.ADD_SET') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-button
              v-if="canEditSet"
              variant="primary"
              :disabled="isAddRecordActive"
              class="ml-1"
              @click="openSetAllModel()"
            >
              <span>{{ $t('BUTTON.CHANGE_TIME_ALL') }} </span>
              <feather-icon icon="ClockIcon" />
            </b-button>
            <b-button
              v-if="canEditSet"
              variant="primary"
              :disabled="isAddRecordActive"
              class="ml-1"
              @click="deleteSetAll()"
            >
              <span>{{ $t('BUTTON.DELETE_ALL') }} </span>
              <feather-icon icon="MinusCircleIcon" />
            </b-button>
            <b-avatar
              v-if="allSetReports"
              rounded
              variant="light-success"
              size="34"
              class="ml-1"
            >
              <feather-icon
                icon="BarChart2Icon"
                class="text-success  cursor-pointer"
                size="30"
                @click="downloadSetReport()"
              />
            </b-avatar>
          </b-col>

          <!-- Search Game-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="competition"
                :options="allGames"
                label="c_string_swe"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_CONTENT')"
                @input="changeCompetitionClick()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card :title="$t('SETS_MILESTONES')">
      <full-calendar
        class="full-calendar"
        :options="calendarOptions"
      />
    </b-card>
    <set-model
      :competition="competition"
      :get-events="getEvents"
      :edit-set="editSet"
    />
    <set-all-model
      :competition="competition"
      :get-events="getEvents"
    />
  </div>
</template>
<script>

import {
  VBToggle,
  VBTooltip,
  BCard,
  BButton,
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction' // For selectable interactions
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'
import { canEditDeleteGame } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import SetModel from './SetModel.vue'
import SetAllModel from './SetAllModel.vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    vSelect,
    BAvatar,
    SetModel,
    SetAllModel,
    FullCalendar,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinDate, mixinList],
  data() {
    return {
      competitions: [],
      canEditSet: true,
      competition: useJwt.getContent(),
      editSet: {
        settime_id: 0,
        comp_id: 0,
        settime: null,
        isCalculated: 0,
      },
      calendarOptions: {
        timeZone: 'local',
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear',

        },
        events: [],
        eventClick: this.handleEventClick,
      },
    }
  },
  created() {
    this.fetchAllGames()
    this.changeCompetitionClick()
    // this.fetchSetReport('1474.xlsx')
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('settime', ['allSettimes']),
    ...mapState('game', ['allGames']),
    ...mapState('report', ['allSetReports']),
  },
  mounted() {
    this.fetchAllGames().then(() => {
      if (router.currentRoute.params.id) {
        this.competition = this.allGames.find(i => i.encrypt_id === router.currentRoute.params.id)
        if (this.competition) {
          this.changeCompetitionClick()
        }
      }
    })
  },
  setup() {
    const isAddRecordActive = ref(true)
    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      isAddRecordActive,
      successMessage,
      errorMessage,
    }
  },
  methods: {
    ...mapActions('settime', ['fetchAllSettimes', 'deleteSettimeAll', 'fetchSetReport']),
    ...mapActions('game', ['fetchAllGames']),
    ...mapActions('report', ['fetchReportByID']),
    getEvents() {
      const list = this.allSettimes.map((item, index) => {
        const eventColor = this.getEventColor(item.settime)

        return {
          id: item.settime_id.toString(),
          title: `Set - ${index + 1} - ${item.settime.split('T')[1].substring(0, 5)}`,
          start: item.settime.replace('Z', ''),
          allDay: true,
          backgroundColor: eventColor,
          borderColor: eventColor,
        }
      })

      // Update the calendarOptions to reflect the new events
      this.calendarOptions = {
        ...this.calendarOptions,
        events: list,
      }
    },
    deleteSetAll() {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')}   ${this.$i18n.t('SET_MILESTONE')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteSettimeAll(this.competition.ID).then(async () => {
              await this.fetchAllSettimes(this.competition.ID)
              this.getEvents()
              this.successMessage(this.$i18n.t('MESSAGE.SET_DELETED'))
            })
          }
        })
    },
    handleEventClick(clickInfo) {
      const eventId = clickInfo.event.id
      const foundObject = this.allSettimes.find(item => item.settime_id.toString() === eventId)
      if (foundObject) {
        this.openSetModel(foundObject)
      } else {
        console.log('Object not found')
      }
    },
    async downloadSetReport() {
      await this.fetchSetReport(`${this.competition.ID}.xlsx`).then(response => {
        if (response) {
          this.successMessage(this.$i18n.t('MESSAGE.REPORT_DOWNLOAD'))
        }
      })
    },
    openSetModel(data) {
      if (data) {
        this.editSet = { ...data }
      }
      this.$bvModal.show('set-modal')
    },
    openSetAllModel() {
      this.$bvModal.show('set-time-modal')
    },
    changeCompetitionClick() {
      if (this.competition) {
        this.fetchAllSettimes(this.competition.ID).then(() => {
          this.isAddRecordActive = false
          useJwt.setContent(this.competition)
          this.fetchReportByID({
            params: `log_type=SET_REPORT&log_object=GAME&log_object_id=${this.competition.ID}`,
          })
          this.canEditSet = canEditDeleteGame(this.competition)
          this.getEvents()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/apps/calendar.scss";

</style>
